<template>
  <div class="organ-detail">
    <van-nav-bar
      title="应急组织详情"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    />
    <div class="detail-info">
      <div class="info-title">组织机构信息</div>
      <div class="info-item">
        <div class="item-label">上级组织：</div>
        <div class="item-value">{{ organDetail.parentName || "--" }}</div>
      </div>
      <div class="info-item">
        <div class="item-label">组织名称：</div>
        <div class="item-value">{{ organDetail.name || "--" }}</div>
      </div>
      <div class="info-item">
        <div class="item-label">责任人：</div>
        <div class="item-value">{{ organDetail.headPersonName || "--" }}</div>
      </div>
      <div class="info-item">
        <div class="item-label">主要责任：</div>
        <div class="item-value">{{ organDetail.duty || "--" }}</div>
      </div>
    </div>
    <div class="member-table">
      <div class="info-title">组织机构成员表</div>
      <table border="1">
        <tr>
          <th>序号</th>
          <th>姓名</th>
          <th>角色</th>
          <th>联系电话</th>
        </tr>
        <tr v-for="item in memberList" :key="item.id">
          <td>1</td>
          <td>{{ item.userName || "--" }}</td>
          <td>{{ item.groupRoleName || "--" }}</td>
          <td>
            <a :href="`tel:${item.phone}`">{{ item.phone || "--" }}</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import api from "./api";

export default {
  name: "OrganDetail",
  data() {
    return {
      organDetail: {},
      memberList: []
    };
  },
  computed: {},
  async created() {
    this.organDetail = { ...this.$route.params, ...this.$route.query };
    const res = await api.queryEmergencyOrgPerson({
      groupId: this.organDetail.id,
      page: 1,
      size: 9999999
    });
    this.memberList = res?.list || [];
  },
  methods: {
    onClickLeft() {
      history.go(-1);
    }
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.organ-detail {
  font-family: PingFangSC-Medium, PingFang SC;
  background: #f7f8fa;
  .detail-info,
  .member-table {
    background: #ffffff;
    width: 100%;
    margin-top: 10px;
    padding: 8px 10px;
    box-sizing: border-box;
    .info-title {
      font-size: 16px;
      font-weight: 600;
      color: #3b4664;
      line-height: 22px;
      margin-bottom: 10px;
    }
    .info-item {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      font-size: 13px;
      font-weight: 400;
      .item-label {
        color: rgba(100, 116, 144, 0.7);
      }
      .item-value {
        color: #3b4664;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    > table {
      width: 100%;
      text-align: center;
      line-height: 20px;
      tr {
        td,
        th {
          padding: 6px;
        }
        th {
          background: #edf1ff;
        }
        td {
          > a {
            color: #3e8dfe;
          }
        }
        &:nth-child(odd) {
          > td {
            background: #f6f8ff;
          }
        }
      }
    }
  }
}
</style>
